import {useSelector} from "react-redux";
import {Form, Formik} from "formik";
import {t} from "i18next";

import {selectTransferedStatuses} from "../../../slices/SampleFunctionalSlice";
import Divider from "../../../utilities/Divider/Divider";
import useSampleFilter from "../../Filters/useSampleFilter";
import {DateInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import FilterButton from "../../SamplesList/SampleParts/FilterButton";

const TransferListFilters = ({storageKey, fetchData}) => {
  const emptyValues = {dateFrom: "", dateTo: "", status: "", term: ""};

  function removeFilter({setValues}) {
    setValues(initialValues);
    fetchData({});
  }

  const {initialValues, areFiltersDefined, onFilter, onDelFilters} = useSampleFilter(
    fetchData,
    removeFilter,
    storageKey,
  );

  const filterOnEnter = (e, values) => {
    if (e.code === "Enter") {
      onFilter(values);
    }
  };

  const statuses = useSelector(selectTransferedStatuses);

  return (
    <Formik
      initialValues={{...emptyValues, ...initialValues}}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
    >
      {({values, setValues}) => (
        <Form className="" style={{width: "100%"}}>
          <div
            style={{
              gap: "1em",
              width: "100%",
            }}
            className="fc jcfs autolabel nowrap aic"
          >
            <div className="flex_container" style={{flexDirection: "column"}}>
              <FilterButton
                onFilter={() => onFilter(values)}
                delFilter={() => onDelFilters(values, setValues)}
                questionTitle={t("Filter")}
                areFiltersDefined={areFiltersDefined}
              />
            </div>
            <div className="row jcsb w100 gap">
              <div></div>
              <div className="row gap2x">
                <div className="row gap">
                  <span>{t("Execution term")}</span>
                  <DateInput name="dateStart" label="From" />
                  <DateInput name="dateEnd" label="To" />
                </div>

                <Select
                  name="status"
                  label={t("Status ")}
                  options={statuses?.map(status => ({title: status})) || []}
                  textMode={true}
                  onKeyDown={e => filterOnEnter(e, values)}
                  style={{width: "20em"}}
                />
              </div>
              <DateInput name="term" label="Term" readOnly={true} />
            </div>
          </div>
          <Divider />
        </Form>
      )}
    </Formik>
  );
};

export default TransferListFilters;
