import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useReactToPrint} from "react-to-print";
import translit from "ua-en-translit";

import {setCurrentTableSampleImages} from "../../../../slices/SampleSlice/SampleSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImgBox from "../../../Inputs/fileSelect/ImgBox/ImgBox";
import FilePreview from "../../../Inputs/fileSelect/preview/FilePreview";
import {TYPE_FORMS} from "../../SamplesList";

const QRSampleModalPart = ({
  sample,
  images,
  addMethod,
  deleteMethod,
  kit,
  typeForm,
  isCreate = false,
  readOnly = false,
  qrCodeUrl: mainQrCodeUrl = "",
}) => {
  const componentRef = React.useRef();
  const dispatch = useDispatch();

  const handlePrintQR = useReactToPrint({
    content: () => componentRef.current,
  });

  const chosenTableSample = useSelector(state => state.samples.modalData[typeForm]?.sample);
  const qrCodeUrlForCreate =
    useSelector(state =>
      kit
        ? state.samples.modalData[typeForm]?.kitQrCodeUrl
        : chosenTableSample
        ? chosenTableSample?.qrCodeUrl
        : state.samples.modalData[typeForm]?.sampleQrCodeUrl,
    ) ?? "";

  const qrCodeUrl =
    mainQrCodeUrl || mainQrCodeUrl === null
      ? mainQrCodeUrl
      : isCreate
      ? qrCodeUrlForCreate
      : kit
      ? kit?.qrCodeUrl
      : typeForm === TYPE_FORMS.UPDATE_KIT
      ? chosenTableSample?.qrCodeUrl
      : sample?.qrCodeUrl;

  const addImg = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    const dataObj = sample ? {sampleId: sample.sampleId, formdata, typeForm} : {kitId: kit.kitId, formdata, typeForm};

    dispatch(addMethod(dataObj)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled" && !kit && typeForm !== TYPE_FORMS.CREATE_SAMPLE) {
        dispatch(setCurrentTableSampleImages({typeForm, images: resp.payload}));
      }
    });
  };

  function onDell(imgUrl) {
    const img = images?.find(item => item.imageUrl === imgUrl);

    dispatch(deleteMethod({imageId: img?.imageId, typeForm, ...(kit ? {kitId: kit?.kitId} : {})}));
  }

  return (
    <div className="qr_whole">
      <div className="qr">
        <div style={{position: "relative"}}>
          <div className="temp">
            {(sample || chosenTableSample || kit) && qrCodeUrl && (
              <img src={qrCodeUrl} alt="qr" width="100%" height="100%" className="qrCodeImg" />
            )}
          </div>
          <div className="temp toPrint" ref={componentRef}>
            {(sample || chosenTableSample || kit) && qrCodeUrl && (
              <img src={qrCodeUrl} alt="qr" width="100%" height="100%" className="qrCodeImg" />
            )}
          </div>
        </div>
        <FunctionButton name="print qr" disabled={!qrCodeUrl} onClick={handlePrintQR} />
      </div>

      <div className="imgBoxS image_wrap color" style={{height: "15em", width: "15em"}}>
        <ImgBox imgs={images?.map(item => item.imageUrl) || []} width={"15em"} height={"15em"} />

        {!readOnly && (
          <FilePreview
            imgs={images?.map(item => item.imageUrl) || []}
            accept={"image/*"}
            addFunc={file => {
              addImg(file);
            }}
            delFunc={file => {
              onDell(file);
            }}
            cropNeed={true}
            disable={kit ? !kit || typeForm === TYPE_FORMS.UPDATE_INSTANCE : !sample}
          />
        )}
      </div>
    </div>
  );
};

export default QRSampleModalPart;
