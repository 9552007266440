import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {getSample, selectSample} from "../../../slices/SampleFunctionalSlice";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModalForm from "../../Modal/FormModal";
import SamplePreview from "../../SamplesList/previewModal/SamplePreview";
import TransferListFilters from "../TransferListFilters/TransferListFilters";

import useSamplesTransferList from "./useSamplesTransferList";

const SamplesTransferList = () => {
  const dispatch = useDispatch();

  const currentSampleToView = useSelector(selectSample);

  const [prewiewModalOpen, setPrewiewModalOpen] = useState(false);

  const {tableColumns, tableData, onFilter, currentSampleTerm, currentSample, setCurrentSample} =
    useSamplesTransferList();

  const onTransferItemDoubleClick = row => {
    dispatch(getSample(row.id)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setPrewiewModalOpen(true);
      }
    });
  };

  return (
    <PageContainer title={t("Samples transfer register")} className="fcCol pb">
      <TransferListFilters storageKey="SamplesTransferList" fetchData={onFilter} />
      <p style={{position: "absolute", right: "2em"}}>{currentSampleTerm}</p>
      <ReactTable
        columns={tableColumns}
        defaultData={tableData}
        onSelect={setCurrentSample}
        onRowDoubleClick={onTransferItemDoubleClick}
        current={currentSample}
        className={"scrollX"}
      />
      <ModalForm
        closable={true}
        Component={SamplePreview}
        active={prewiewModalOpen}
        setModalActive={setPrewiewModalOpen}
        label={"SKU №" + currentSampleToView?.skuNumber || ""}
        sample={currentSampleToView}
      />
    </PageContainer>
  );
};

export default SamplesTransferList;
