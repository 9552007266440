import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";

import useSelectOptions from "../../hook/useSelectOptions";
import {getStatusCatalog} from "../../slices/GeneralCatalogSlice";
import {fetchAllCategories, fetchAllSubGroups} from "../../slices/SampleSlice/operations";
import {setCurrentKitId} from "../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId} from "../../slices/SampleSlice/selectors";
import {selectBrandManagers, selectManagers} from "../../slices/UserSlice";
import {checkEmptyFields} from "../../utilities/checkEmptyFields";
import {checkEmptyFilterFields} from "../../utilities/checkEmptyFilterFields";
import useToaster from "../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../utilities/makeFilterManager/makeFilterManager";
import {trimFilters} from "../../utilities/trimFilters";

export const STATUSES = Object.freeze({ON_STOCK: "On Stock", UNDER_REPORT: "Under Report"});

const useSampleFilter = (fetchData, onRemoveFilter, storageKey) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const Toaster = useToaster();

  const brandManagers = useSelector(selectBrandManagers);
  const managers = useSelector(selectManagers),
    statuses = useSelector(getStatusCatalog);

  const currentKitId = useSelector(selectCurrentKitId);

  //filter manager
  const SampleFilterManager = makeFilterManager(storageKey);
  // let savedFilters = SampleFilterManager.getFilters();
  // if (savedFilters) {
  //   savedFilters = trimFilters(savedFilters);
  // }

  // if (currentKitId) {
  //   if (savedFilters) {
  //     savedFilters.kitId = currentKitId;
  //   } else {
  //     savedFilters = {kitId: currentKitId};
  //   }
  // }

  const [areFiltersDefined, setFiltersDefined] = useState(false);
  const [savedFilters, setSavedFilters] = useState(null);

  useEffect(() => {
    let savedFilters_ = SampleFilterManager.getFilters();
    if (savedFilters_) {
      setFiltersDefined(savedFilters_ ? Boolean(Object.values(checkEmptyFields(savedFilters_)).length) : false);
      setSavedFilters(trimFilters(savedFilters_));
    }
  }, [areFiltersDefined, currentKitId]);

  useEffect(() => {
    if (!SampleFilterManager.getFilters()) setFiltersDefined(false);
  }, [(SampleFilterManager.getFilters() ?? []).length]);

  // useEffect(() => {
  //   if (currentKitId) {
  //     setFiltersDefined(true);
  //   }
  //   if (!currentKitId) {
  //     setFiltersDefined(false);
  //   }
  // }, [currentKitId]);

  function calcSubGroupDisabled(values) {
    const selectedGroupId = values.groupId;

    return selectedGroupId === "";
  }

  function calcIsCategoryDisabled(values) {
    const selectedMaterial = values.materialId;

    return selectedMaterial === "";
  }

  function onGroupSelect(selectedGroupId) {
    if (selectedGroupId !== "") {
      dispatch(fetchAllSubGroups(selectedGroupId));
    }
  }

  function onMaterialSelect(selectedMaterialId) {
    if (selectedMaterialId !== "") {
      dispatch(fetchAllCategories(selectedMaterialId));
    }
  }

  function onFilter(values) {
    Toaster.success("Filters applied");

    if (values.dateEnd !== "" && values.dateStart !== "") {
      const formattedFromDate = moment(values.dateStart, "YYYY-MM-DD");
      const formattedEndDate = moment(values.dateEnd, "YYYY-MM-DD");
      if (formattedEndDate.isBefore(formattedFromDate)) {
        Swal.fire({
          title: "",
          text: t("date filters warn"),
          icon: "error",
        });
        return;
      }
    }
    SampleFilterManager.setFilters(checkEmptyFilterFields(values));
    setFiltersDefined(true);

    fetchData(values);
    dispatch(setCurrentKitId(null));
    // const samplesFilters = makeSamplesFilters(values);
    // const filters = checkEmptyFields(samplesFilters);
    // const kitFilters = makeKitsFilters(values);

    // dispatch(getAllSamples({...filters, page: 1}));
    // if (Object.values(checkEmptyFields(kitFilters)).length !== 0) {
    //   dispatch(getAllKits(kitFilters));
    // }
  }

  function onDelFilters(values, setValues) {
    Toaster.success("Filters deleted");

    SampleFilterManager.clearFilters();
    onRemoveFilter({values, setValues});
    // dispatch(setCurrentKitId(null));
    // // console.log("uef in DEL");
    // dispatch(getAllSamples());

    // const kitFilters = makeKitsFilters(values);
    // if (Object.values(checkEmptyFields(kitFilters)).length !== 0) {
    //   dispatch(getAllKits());
    // }
    setSavedFilters(null);
    setFiltersDefined(false);
  }

  const initialValues = savedFilters ?? {
    factoryId: "",
    kitId: "",
    skuNumber: "",
    status: "",
    officeId: "",
    groupId: "",
    subgroupId: "",
    materialId: "",
    categoryId: "",
    textureId: "",
    collection: "",
    color: "",
    name: "",
    brandManagerId: "",
    managerId: "",
    dateStart: "",
    dateEnd: "",
  };

  const statusOptions =
    statuses?.map(status => ({
      title: status?.name,
      value: status?.statusId,
    })) || [];
  const brandManagerOptions =
    brandManagers?.map(brandManager => ({
      title: `${brandManager.lastName} ${brandManager.firstName}`,
      value: brandManager.userId,
    })) ?? [];

  const accManagerOptions =
    brandManagers
      ?.concat(managers)
      ?.map(manager => ({title: `${manager.lastName} ${manager.firstName}`, value: manager.userId})) ?? [];

  const {
    officesOptions,
    factoriesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    texturesOptions,
    categoriesOptions,
  } = useSelectOptions();

  return {
    initialValues,
    factoriesOptions,
    statusOptions,
    officesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    categoriesOptions,
    texturesOptions,
    brandManagerOptions,
    accManagerOptions,
    areFiltersDefined,
    onFilter,
    onDelFilters,
    onGroupSelect,
    onMaterialSelect,
    calcSubGroupDisabled,
    calcIsCategoryDisabled,
  };
};

export default useSampleFilter;
