import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../hook/useColumns";
import {getTransferedSamples, selectTransferedSamples} from "../../../slices/SampleFunctionalSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import samplesTableDataService from "../../SamplesList/constants/samplesTableDataService";

import {SamplesTransferListTableColumns} from "./constants";

const useSamplesTransferList = () => {
  const dispatch = useDispatch();
  const transferedSamples = useSelector(selectTransferedSamples);
  const tableColumns = useColumns(SamplesTransferListTableColumns);
  const tableData = samplesTableDataService(transferedSamples);

  const [currentSample, setCurrentSample] = useState(null);
  const [currentSampleTerm, setCurrentSampleTerm] = useState("");

  const SampleFilterManager = makeFilterManager("SamplesTransferList");

  useEffect(() => {
    let savedFilters_ = SampleFilterManager.getFilters();
    onFilter(savedFilters_);
  }, []);

  useEffect(() => {
    const sample = currentSample ? transferedSamples?.find(samp => samp.sampleId === currentSample) : null;
    setCurrentSampleTerm(sample ? dateFormat(sample?.pendingAction?.actionPlanDate) : "");
  }, [currentSample, transferedSamples]);

  const onFilter = params => {
    dispatch(getTransferedSamples(params));
    setCurrentSample(null);
  };

  return {tableColumns, tableData, onFilter, currentSampleTerm, currentSample, setCurrentSample};
};

export default useSamplesTransferList;
