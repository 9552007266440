import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import IndexPage from "../../pages/IndexPage";
import WareHousePage from "../../pages/WareHousePage";
import LoginForm from "../authorization/LoginForm";
import PasswordForm from "../authorization/PasswordForm";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import RequireAuth from "../hoc/RequireAuth";
import KitsList from "../KitsWithSamplesList/KitsList";
// import KitsList from "../KitsList/KitsList";
import NavBar from "../NavBar/NavBar";
import SampleGuideModal from "../SamplesList/SampleModals/SampleGuideModal/SampleGuideModal";
import SampleRegistrManager from "../SamplesList/SampleModals/SampleRegistrManager";
import SamplesListNew from "../SamplesListNew/SamplesListNew";
import KitsTransferList from "../TransferList/KitsTransferList/KitsTransferList";
import SamplesTransferList from "../TransferList/SamplesTransferList/SamplesTransferList";
import TranslationButton from "../Translation/TranslationButton";
import Deadlines from "../WarehouseSettings/Deadlines/Deadlines";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "../../Styles/Main.scss";
import "../../Styles/List.scss";
import "../../Styles/Buttons.scss";
import "../Modal/modal.scss";

function App() {
  const {t} = useTranslation();
  const {i18n} = useTranslation();

  useEffect(() => {
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);

  // const appHeight = useCallback(() =>{
  //   const doc = document.documentElement
  //   doc.style.setProperty('--app-height', `${window.innerHeight-30}px`)
  // },[])

  // useEffect(() => {
  //   appHeight()
  //   window.addEventListener('resize', appHeight)

  //   return () => window.removeEventListener("resize", appHeight);
  // }, [appHeight]);

  const {token, user} = useSelector(state => state.user);

  return (
    <>
      <Router>
        <NavBar />
        <div className="App">
          <div className="App__container">
            <Routes>
              <Route
                path="/ba3a/*"
                element={
                  <RequireAuth>
                    <IndexPage />
                  </RequireAuth>
                }
              >
                <Route path="warehouse/*" element={<WareHousePage />}>
                  <Route path="kits" element={<KitsList />} />
                  <Route path="samples" element={<SamplesListNew />} />
                  <Route path="transferList/kits" element={<KitsTransferList />} />
                  <Route path="transferList/samples" element={<SamplesTransferList />} />
                </Route>
                <Route path="settings/*">
                  <Route path="deadlines" element={<Deadlines />} />
                  <Route path="guides" element={<SampleGuideModal />} />
                  <Route path="users" element={<SampleRegistrManager />} />
                </Route>
              </Route>

              <Route
                path="/"
                element={
                  <ErrorBoundary>
                    <Navigate to="/login" />
                  </ErrorBoundary>
                }
              />
              <Route path="/translate" element={<TranslationButton />} />
              <Route
                path="/login"
                element={
                  user && !token ? (
                    <ErrorBoundary>
                      <Navigate to="/password" />
                    </ErrorBoundary>
                  ) : (
                    <ErrorBoundary>
                      <LoginForm />
                    </ErrorBoundary>
                  )
                }
              />
              <Route
                path="/password"
                element={user && token ? <Navigate to="/ba3a/warehouse/samples" /> : <PasswordForm />}
              />
              <Route
                path="/accessdenied"
                element={
                  <RequireAuth>
                    <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
                      <p> {t("Access is denied")}</p>
                    </div>
                  </RequireAuth>
                }
              />
              <Route
                path="/*"
                element={
                  <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
                    <p> {t("Under development")}</p>
                  </div>
                }
              />
            </Routes>
            <ToastContainer position="top-center" hideProgressBar />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
