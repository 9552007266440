import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {getKit, getSample, selectKit, selectSample} from "../../../slices/SampleFunctionalSlice";
import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModalForm from "../../Modal/FormModal";
import KitPreview from "../../SamplesList/previewModal/KitPreview";
import SamplePreview from "../../SamplesList/previewModal/SamplePreview";
import TransferListFilters from "../TransferListFilters/TransferListFilters";

import useKitsTransferList from "./useKitsTransferList";

const KitsTransferList = () => {
  const dispatch = useDispatch();

  const [samplePrewiewModalOpen, setSamplePrewiewModalOpen] = useState(false);
  const [kitPrewiewModalOpen, setKitPrewiewModalOpen] = useState(false);

  const currentSampleToView = useSelector(selectSample);
  const currentKitToView = useSelector(selectKit);

  const {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onFilter,
    currentKit,
    setCurrentKit,
    currentSample,
    setCurrentSample,
    currentSampleTerm,
  } = useKitsTransferList();

  const onTransferSampleDoubleClick = row => {
    dispatch(getSample(row.id)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setSamplePrewiewModalOpen(true);
      }
    });
  };

  const onTransferKitDoubleClick = row => {
    dispatch(getKit({kitId: row?.id})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setKitPrewiewModalOpen(true);
      }
    });
  };

  return (
    <PageContainer title={t("Kits transfer register")} className="fcCol pb">
      <TransferListFilters storageKey="KitsTransferList" fetchData={onFilter} />
      <p style={{position: "absolute", right: "2em"}}>{currentSampleTerm}</p>
      <ReactTable
        columns={upperTableColumns}
        defaultData={upperTableData}
        onSelect={setCurrentKit}
        onRowDoubleClick={onTransferKitDoubleClick}
        current={currentKit}
        className={"scrollX"}
      />
      <Divider />
      <ReactTable
        columns={lowerTableColumns}
        defaultData={lowerTableData}
        onSelect={setCurrentSample}
        onRowDoubleClick={onTransferSampleDoubleClick}
        current={currentSample}
        className={"scrollX"}
      />
      <ModalForm
        closable={true}
        Component={SamplePreview}
        active={samplePrewiewModalOpen}
        setModalActive={setSamplePrewiewModalOpen}
        label={"SKU №" + currentSampleToView?.skuNumber || ""}
        sample={currentSampleToView}
      />
      <ModalForm
        closable={true}
        Component={KitPreview}
        active={kitPrewiewModalOpen}
        setModalActive={setKitPrewiewModalOpen}
        label={"KIT №" + currentKitToView?.skuNumber || ""}
        kit={currentKitToView}
      />
    </PageContainer>
  );
};

export default KitsTransferList;
