import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet} from "react-router-dom";

import {
  fetchCountriesCatalog,
  fetchFactoriesCatalog,
  fetchOfficesCatalog,
  fetchRolesCatalog,
  fetchStatusCatalog,
} from "../slices/GeneralCatalogSlice";
import {fetchAllGroups, fetchAllMaterials, fetchAllTextures} from "../slices/SampleSlice/operations";
import {getAllUsers, getCurrentUser} from "../slices/UserSlice";

const WareHousePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const token = useSelector(state => state.user.token);

  useEffect(() => {
    if (token && user) {
      dispatch(fetchOfficesCatalog());
      dispatch(fetchRolesCatalog());
      dispatch(fetchCountriesCatalog());
      dispatch(fetchFactoriesCatalog());
      dispatch(getAllUsers());
      dispatch(fetchAllGroups());
      dispatch(fetchAllMaterials());
      dispatch(fetchAllTextures());
      dispatch(fetchStatusCatalog());
    }
  }, [token, user]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default WareHousePage;
