import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import useColumns from "../../../hook/useColumns";
import {
  clearNewSample,
  deleteSample,
  deselectKitImages,
  getAllSamples,
  getSample,
  selectAllSamples,
  selectSample,
  selectSamplesPage,
  selectSamplesTotalPageas,
  setSample,
} from "../../../slices/SampleFunctionalSlice";
import {setCurrentSampleId, setModalData, updateModalData} from "../../../slices/SampleSlice/SampleSlice";
import {selectCurrentSampleId} from "../../../slices/SampleSlice/selectors";
import {getCurrentUser} from "../../../slices/UserSlice";
import useSwal from "../../../utilities/hooks/useSwal";
import useToaster from "../../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import samplesTableDataService from "../../SamplesList/constants/samplesTableDataService";
import makeSamplesFilters from "../../SamplesList/SampleParts/SampleFilter/utils/makeSamplesFilters";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";
import {NO_KIT_SAMPLE_LIST_FILTERS_STORAGE_KEY} from "../SamplesListNew";
import {NO_KIT_SAMPLE_TABLE_COLUMNS} from "../tabs/constants";

const useNoKitSampleBlock = ({
  setFormType,
  setShowModal,
  setTabValue,
  setTabs,
  setTabProps,
  setTabLabel,
  setCurrentSample,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const Toaster = useToaster();
  const SWAL = useSwal();

  const allSamples = useSelector(selectAllSamples),
    totalPages = useSelector(selectSamplesTotalPageas),
    currPage = useSelector(selectSamplesPage);

  const currUser = useSelector(getCurrentUser);
  const currentSampleId = useSelector(selectCurrentSampleId);
  const currentSample = allSamples?.find(sample => sample.sampleId === currentSampleId) ?? null;
  const transformTabSample = useSelector(state => state.samples.modalData[TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT].sample);
  const sample = useSelector(selectSample);
  const openedInEditSampleId = sample?.sampleId;

  const SampleFilterManager = makeFilterManager(NO_KIT_SAMPLE_LIST_FILTERS_STORAGE_KEY);
  const filters = SampleFilterManager.getFilters() ?? {};
  const samplesFilters = makeSamplesFilters(filters);

  const lowerTableColumns = useColumns(NO_KIT_SAMPLE_TABLE_COLUMNS);

  const [samplePrewiewModalShow, setSamplePrewiewModalShow] = useState(false);

  const instanceTableData = samplesTableDataService(allSamples);

  useEffect(() => {
    dispatch(getAllSamples({...samplesFilters, page: 1, isFree: true}));
  }, []);

  const isOwn = currentSample?.brandManager?.userId === currUser?.userId;

  const onCreateSample = async () => {
    let createTabIndex = null;
    setTabs(tabs => {
      createTabIndex = tabs.findIndex(tab => tab.props.typeForm === TYPE_FORMS.CREATE_SAMPLE);
      return tabs;
    });

    setCurrentSample(null);
    dispatch(clearNewSample());

    if (createTabIndex && createTabIndex > 0) {
      SWAL.confirm({
        title: "You can open only one sample form creating. All unsaved data will be deleted",
        confirmFunc: () => {
          dispatch(setModalData({typeForm: TYPE_FORMS.CREATE_SAMPLE, formData: {}}));
          setTabValue(createTabIndex);
        },
      });
    } else {
      setFormType(TYPE_FORMS.CREATE_SAMPLE);
    }
  };

  function formValues(item) {
    return {
      factoryId: item?.factoryId || "",
      skuNumber: item?.skuNumber || "",
      groupId: item?.groupId || "",
      subgroupId: item?.subgroupId || "",
      materialId: item?.materialId || "",
      categoryId: item?.categoryId || "",
      textureId: item?.textureId || "",
      collection: item?.collection || "",
      color: item?.color || "",
      name: item?.name || "",
      state: item?.state || "",
      brandManager: `${item?.brandManager?.lastName ?? ""} ${item?.brandManager?.firstName ?? ""}`,
    };
  }

  function onEditSampleFunc({isSampleEditOpen}) {
    if (currentSample?.status?.statusId !== 1) {
      Swal.fire({
        title: "",
        text: t("forbidd edit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.status?.statusId === 1 && isOwn) {
      if (isSampleEditOpen) {
        let sampleUpdateTabValue;
        setTabs(prev => {
          const updateSampleTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.EDIT_SAMPLE);
          updateSampleTab.label = `${t("edit s")} №${currentSample?.skuNumber ?? ""}`;
          sampleUpdateTabValue = prev.indexOf(updateSampleTab);
          return prev;
        });
        setTabValue(sampleUpdateTabValue < 0 ? 0 : sampleUpdateTabValue);
        dispatch(updateModalData({typeForm: TYPE_FORMS.EDIT_SAMPLE, formData: formValues(currentSample)}));
      } else {
        setFormType(TYPE_FORMS.EDIT_SAMPLE);
        setTabLabel(`${t("edit s")} №${currentSample?.skuNumber ?? ""}`);
      }
      dispatch(deselectKitImages());

      dispatch(getSample(currentSampleId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setCurrentSample(res.payload);
        }
      });

      setShowModal(true);
    } else {
      Swal.fire({
        title: "",
        text: t("forbid edit not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  }

  const onEditSample = () => {
    if (currentSampleId === openedInEditSampleId) {
      Toaster.info("The sample has already been opened for editing");
      return;
    }
    if (openedInEditSampleId && currentSampleId !== openedInEditSampleId) {
      SWAL.confirm({
        title: "You can open only one sample form editting",
        confirmFunc: () => {
          onEditSampleFunc({isSampleEditOpen: true});
        },
      });
    } else {
      onEditSampleFunc({isSampleEditOpen: false});
    }
  };

  const onInstanceDelete = () => {
    if (openedInEditSampleId === currentSample.sampleId) {
      Toaster.error("Sample that is opened in edit tab can`t be deleted");
      return;
    }

    if (currentSample?.statusId === 2 || currentSample?.statusId === 3) {
      Swal.fire({
        title: "",
        text: t("forbidd"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.statusId === 1 && isOwn) {
      Swal.fire({
        title: "",
        text: t("del sample"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(deleteSample(currentSample.sampleId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Sample deleted");
              let viewedSampleId = null;
              setTabs(prev => {
                const viewTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.VIEW_SAMPLE);
                if (viewTab) viewedSampleId = viewTab.props.sampleId;
                return prev;
              });
              if (viewedSampleId === currentSample.sampleId) {
                setTabs(prev => prev.filter(tab => tab.props.typeForm !== TYPE_FORMS.VIEW_SAMPLE));
              }
            }
          });
          setCurrentSample(null);
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: t("forbid not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  };

  const onDublicateSample = () => {
    if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbit to dubl"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else {
      dispatch(setSample(currentSample));
      setFormType(TYPE_FORMS.DUBLICATE_SAMPLE);
      setTabLabel(`${t("dubl s")} №${currentSample?.skuNumber ?? ""}`);
    }
  };

  function onTransferSampleToKit() {
    if (transformTabSample && transformTabSample?.sampleId === currentSampleId) {
      Toaster.error("The sample has already been opened for transfer");
      return;
    }

    if (transformTabSample && transformTabSample?.sampleId !== currentSampleId) {
      SWAL.confirm({
        title: "You can open only one sample form transfer",
        confirmFunc: () => {
          let sampleTransferTabValue;
          setTabs(prev => {
            const transferSampleTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT);
            transferSampleTab.label = `${t("transfer s")} №${currentSample?.skuNumber ?? ""}`;
            sampleTransferTabValue = prev.indexOf(transferSampleTab);
            return prev;
          });
          setTabValue(sampleTransferTabValue < 0 ? 0 : sampleTransferTabValue);
          dispatch(setModalData({typeForm: TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT, formData: {sample: currentSample}}));
        },
      });
    } else {
      setFormType(TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT);
      setTabLabel(`${t("transfer s")} №${currentSample?.skuNumber ?? ""}`);
      dispatch(setModalData({typeForm: TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT, formData: {sample: currentSample}}));
    }
  }

  const onSampleSelect = id => {
    if (currentSample && id === currentSample.sampleId) {
      setCurrentSample(null);
      dispatch(setCurrentSampleId(null));
      return;
    }

    const currSamp = allSamples?.find(item => item.sampleId === id);
    setCurrentSample(currSamp);
    dispatch(setCurrentSampleId(currSamp?.sampleId));
  };

  const onRowDoubleClick = row => {
    const currSamp = allSamples?.find(item => item.sampleId === row.id);
    setCurrentSample(currSamp);
    setSamplePrewiewModalShow(true);
  };

  const initialValues = {};

  const pagg = async () => {
    if (totalPages === currPage || totalPages === 1) return;
    dispatch(getAllSamples({...samplesFilters, page: currPage + 1, isFree: true}));
  };
  const sampleControlBtnsSize = 2.2;

  function onViewSample() {
    let sampleViewTabValue;
    setTabs(prev => {
      const viewSampleTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.VIEW_SAMPLE);
      if (viewSampleTab) {
        viewSampleTab.label = `${t("view s")} №${currentSample?.skuNumber ?? ""}`;
      }
      sampleViewTabValue = prev.indexOf(viewSampleTab);
      return sampleViewTabValue > 0
        ? prev.map(tab => {
            if (tab.props.typeForm === TYPE_FORMS.VIEW_SAMPLE) {
              tab.props.sampleId = currentSample?.sampleId;
            }
            return tab;
          })
        : prev;
    });
    if (sampleViewTabValue && sampleViewTabValue > 0) {
      setTabValue(sampleViewTabValue);
    } else {
      setTabLabel(`${t("view s")} №${currentSample?.skuNumber ?? ""}`);
      setTabProps({sampleId: currentSample?.sampleId});
      setFormType(TYPE_FORMS.VIEW_SAMPLE);
    }
  }

  function onModalClose() {
    dispatch(getAllSamples({...samplesFilters, page: 1, isFree: true}));
  }

  return {
    instanceTableData,
    lowerTableColumns,
    onSampleSelect,
    onRowDoubleClick,
    pagg,
    initialValues,
    onCreateSample,
    currUser,
    sampleControlBtnsSize,
    onEditSample,
    onViewSample,
    onDublicateSample,
    onInstanceDelete,
    samplePrewiewModalShow,
    setSamplePrewiewModalShow,
    onTransferSampleToKit,
    currentSampleId,
    onModalClose,
  };
};

export default useNoKitSampleBlock;
