import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";

import {fetchData} from "../../../hook/axios.hook";
import {
  deselectKit,
  deselectSample,
  makeActionWithKit,
  makeActionWithSample,
} from "../../../slices/SampleFunctionalSlice";
import {setCurrentKitId, setCurrentSampleId} from "../../../slices/SampleSlice/SampleSlice";
import {getError} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {DateInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

const ActionWithChosen = ({kitId, sampleId}) => {
  const dispatch = useDispatch();
  const actionOptions = [
    {title: t("for write-off"), value: "forWriteOff"},
    {title: `${t("for write-off")}(MK)`, value: "writeOff"},
  ];

  const {
    values: {actionType},
    setFieldValue,
  } = useFormikContext();

  const forWriteOff = () => {
    Swal.fire({
      title: "",
      text: [t("Sure")],
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("Cancel")],
    }).then(value => {
      if (value.isConfirmed) {
        if (kitId && sampleId) {
          Swal.fire({
            title: "",
            text: [t("Make action with")],
            icon: "question",
            confirmButtonText: [t("Kit")],
            showCancelButton: true,
            cancelButtonText: [t("Sample")],
          }).then(value => {
            if (value.isConfirmed) {
              dispatch(makeActionWithKit({actionType, id: kitId}));
            } else if (value.isDenied) {
              dispatch(makeActionWithSample({actionType, id: sampleId}));
            }
          });
          return;
        } else if (kitId) {
          dispatch(makeActionWithKit({actionType, id: kitId}));
        } else {
          dispatch(makeActionWithSample({actionType, id: sampleId}));
        }
        setFieldValue("actionType", null);
        dispatch(deselectKit());
        dispatch(deselectSample());
        dispatch(setCurrentSampleId(null));
        dispatch(setCurrentKitId(null));
      }
    });
  };

  return (
    <div className="row gap aic">
      <Select
        label="Action with chosen"
        name="actionType"
        nullOption={t("choose an action")}
        options={actionOptions}
        optionsAbove
        selectOnly={true}
      />

      {/* <DateInput name="actionDate" noLabel /> */}
      <FunctionButton name="Ok" onClick={forWriteOff} width={"4em"} disabled={!actionType || !(kitId || sampleId)} />
    </div>
  );
};

export default withFormik({enableReinitialize: true, mapPropsToValues: () => ({actionType: null})})(ActionWithChosen);
