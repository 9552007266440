import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import {selectSample} from "../../../slices/SampleFunctionalSlice";
import {getCurrentUser} from "../../../slices/UserSlice";
import ReadOnlyFields from "../../../utilities/ReadOnlyFields";
import {Select, TextAreaInput, TextInput} from "../../Inputs";
import QRSampleModalPart from "../SampleUniModal/SampleUniModalParts/QRSampleModalPart";

const SamplePreview = ({sample}) => {
  const {t} = useTranslation();

  const currUser = useSelector(getCurrentUser);

  const initialValues = useMemo(() => {
    const {factory, kit, group, subgroup, material, category, texture, color, collection, name, skuNumber, state} =
      sample;
    return {
      // brandManager: currUser?.lastName + " " + currUser?.firstName,
      factoryId: factory?.factoryName || "",
      kitSku: kit?.skuNumber || "-",
      skuNumber,
      groupId: group?.groupName || "",
      subgroupId: subgroup?.subgroupName || "",
      materialId: material?.materialName || "",
      categoryId: category?.categoryName || "",
      textureId: texture?.textureName || "",
      collection,
      color,
      name,
      state,
    };
  }, [sample]);
  return (
    <>
      <div className="fc hw100 aic">
        <div className="w g1 jcc">
          <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            <Form style={{paddingTop: "0.5em"}}>
              <ReadOnlyFields>
                <div className="fdr gap2x">
                  <TextInput label="SKU №" name="skuNumber" labelStyles={{width: "4em"}} />
                  <TextInput label="kitSKU №" name="kitSku" labelStyles={{width: "4em"}} />
                </div>
                <div className="lower_selects">
                  <TextInput label={t("Smp g")} name="groupId" generalClassName="lower_selects_item" />
                  <TextInput label={t("Factory")} name="factoryId" generalClassName="lower_selects_item" />
                  <TextInput label={t("sgr")} name="subgroupId" generalClassName="lower_selects_item" />
                  <TextInput label={t("material")} name="materialId" generalClassName="lower_selects_item" />
                  <TextInput label={t("categ")} name="categoryId" generalClassName="lower_selects_item" />
                  <TextInput label={t("texture")} name="textureId" generalClassName="lower_selects_item" />
                </div>
                <div className="fdr jcsb" style={{width: "100.5%"}}>
                  <TextAreaInput name="collection" placeholder={t("col") + ": "} width="50%" height="3em" />
                  <TextAreaInput name="color" placeholder={t("Color") + ": "} width="50%" height="3em" />
                </div>
                <TextAreaInput name="name" placeholder={t("samp nom") + ": " + t("1000")} width="101%" height="3em" />
                <TextAreaInput name="state" placeholder={t("samp note") + ": " + t("1000")} width="101%" height="3em" />
              </ReadOnlyFields>
            </Form>
          </Formik>
          <QRSampleModalPart readOnly={true} sample={sample} images={sample.images} />
        </div>
      </div>
    </>
  );
};

export default SamplePreview;
