import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import Swal from "sweetalert2";

import {fetchData} from "../../../hook/axios.hook";
import useColumns from "../../../hook/useColumns";
import useSelectOptions from "../../../hook/useSelectOptions";
import {addNewSample, deleteSample, dublicateSample, updateSample} from "../../../slices/SampleFunctionalSlice";
import {fetchAllCategories, fetchAllSubGroups} from "../../../slices/SampleSlice/operations";
import {closeCurrentTab, getInfo, getSuccess, updatePageTabProps} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../utilities/ToolTip";
import ReactTable from "../../conteiners/ReactTable";
import {Select, TextAreaInput, TextInput} from "../../Inputs";
import {SampleUniModTableColumns} from "../../SamplesList/constants/columns";
import samplesForKitModalDataService from "../../SamplesList/constants/samplesForKitModalDataService";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";

import KitForm from "./KitForm";
import QR from "./QR";

const SampleForm = ({sample, kitId, onSubmit, formType, formInitialValues, setInitialFormValues, ...props}) => {
  const {groupsOptions, subGroupsOptions, materialsOptions, texturesOptions, categoriesOptions, factoriesOptions} =
    useSelectOptions();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const uniTableColumns = useColumns(SampleUniModTableColumns);

  const {setValues, values, dirty, initialValues} = useFormikContext();
  const [kit, setKit] = useState(null);

  useEffect(() => {
    if (kitId) {
      fetchData(`/kits/get/${kitId}`, "get").then(resp => {
        setKit(resp);
        setInitialFormValues(prev => ({
          ...prev,
          ...formInitialValues,
          kitSku: resp?.skuNumber || "",
          setDescArea: resp?.name || "",
          brandManager: resp?.brandManager ? resp.brandManager.firstName + " " + resp.brandManager.lastName : "",
        }));
      });
    }
  }, [kitId, formInitialValues]);

  const areAllRequiredFieldsFilled =
    values?.materialId &&
    values?.factoryId &&
    values?.groupId &&
    values?.materialId &&
    values?.factoryId &&
    values?.name &&
    values?.name !== "" &&
    (formType === TYPE_FORMS.UPDATE_KIT_SAMPLE ? values?.skuNumber !== "" : true);
  const disableFields = formType === TYPE_FORMS.VIEW_KIT_SAMPLE || !!values?.sampleId;

  useEffect(() => {
    if (values?.materialId) {
      dispatch(fetchAllCategories(values?.materialId));
    }
  }, [values?.materialId]);

  useEffect(() => {
    if (values?.groupId) {
      dispatch(fetchAllSubGroups(values?.groupId));
    }
  }, [values?.groupId]);

  const onDublicateKitInstance = () => {
    dispatch(
      updatePageTabProps({
        pageType: formType,
        props: {
          formInitialValues: {...values, skuNumber: "", color: "", state: "", sampleId: null, sampleQRcode: null},
          sample: {images: []},
        },
      }),
    );
  };
  const onAddMore = () => {
    setTimeout(() => {
      dispatch(
        updatePageTabProps({
          pageType: formType,
          props: {
            formInitialValues: {
              ...values,
              factoryId: null,
              skuNumber: "",
              groupId: null,
              subgroupId: values.subgroupId !== "" ? null : "",
              materialId: null,
              categoryId: values.categoryId !== "" ? null : "",
              textureId: null,
              collection: "",
              color: "",
              name: "",
              state: "",
              sampleId: null,
              sampleQRcode: null,
            },
            sample: {images: []},
          },
        }),
      );
    }, []);
  };
  const addImages = formdata => {
    fetchData(`/samples/images/add/${sample?.sampleId || values?.sampleId}`, "post", formdata).then(resp => {
      dispatch(updatePageTabProps({pageType: formType, props: {sample: {...sample, images: resp}}}));
    });
  };

  const delImg = imageId => {
    fetchData(`samples/images/remove/${imageId}`, "delete").then(resp => {
      dispatch(updatePageTabProps({pageType: formType, props: {sample: {...sample, images: resp}}}));
    });
  };

  const data = useMemo(() => samplesForKitModalDataService(kit?.samples || []), [kit]);
  return (
    <div className="h100" style={{display: "flex", flexDirection: "column"}}>
      <KitForm kit={kit} />
      <div className="w">
        <div className="part_width">
          <div className="lower_selects">
            <TextInput
              label="SKU №"
              name="skuNumber"
              generalClassName="lower_selects_item"
              labelStyles={{width: "4em"}}
              disabled={disableFields}
            />
            <Select
              label={t("Factory") + "*"}
              name="factoryId"
              options={factoriesOptions}
              minFilterLength={1}
              // selectOnly
              generalClassName="lower_selects_item"
              readOnly={disableFields}
            />
            <Select
              label={t("Smp g") + "*"}
              name="groupId"
              options={groupsOptions}
              // selectOnly
              generalClassName="lower_selects_item"
              readOnly={disableFields}
            />
            <Select
              label={t("sgr")}
              name="subgroupId"
              options={subGroupsOptions}
              // selectOnly
              generalClassName="lower_selects_item"
              readOnly={disableFields}
            />
            <Select
              label={t("material") + "*"}
              name="materialId"
              options={materialsOptions}
              minFilterLength={1}
              // selectOnly
              generalClassName="lower_selects_item"
              readOnly={disableFields}
            />
            <Select
              label={t("categ")}
              name="categoryId"
              options={categoriesOptions}
              minFilterLength={1}
              // selectOnly
              generalClassName="lower_selects_item"
              readOnly={disableFields}
            />
            <Select
              label={t("texture")}
              name="textureId"
              options={texturesOptions}
              // selectOnly
              generalClassName="lower_selects_item"
              readOnly={disableFields}
            />
          </div>
          <div className="fdr jcsb" style={{width: "100.5%"}}>
            <TextAreaInput
              label=""
              name="collection"
              placeholder={t("col") + ": "}
              width="50%"
              height="3em"
              className=""
              disabled={disableFields}
            />
            <TextAreaInput
              label=""
              name="color"
              placeholder={t("Color") + ": "}
              width="50%"
              height="3em"
              className=""
              disabled={disableFields}
            />
          </div>
          <TextAreaInput
            label=""
            name="name"
            placeholder={t("samp nom") + "*" + ": " + t("1000")}
            width="101%"
            height="3em"
            className=""
            disabled={disableFields}
          />
          <TextAreaInput
            label=""
            name="state"
            placeholder={t("samp note") + ": " + t("1000")}
            width="101%"
            height="3em"
            className=""
            disabled={disableFields}
          />

          <div className="fdr jcsb mt">
            {formType === TYPE_FORMS.CREATE_KIT_SAMPLE && (
              <div className={"gap fc nowrap"}>
                <FunctionButton
                  autoWidth
                  name="add more"
                  onClick={onAddMore}
                  disabled={!values?.sampleId}
                  width={"8em"}
                />
                <FunctionButton
                  name="dubl"
                  onClick={onDublicateKitInstance}
                  disabled={!values?.sampleId}
                  width={"10em"}
                />
              </div>
            )}
            {onSubmit && (
              <div className="fdr jcfe gap mt w100">
                <ToolTip title={t("Required fields InstanceModalPart")} placement="bottom">
                  <FunctionButton
                    name="Save"
                    onClick={() => onSubmit(values)}
                    key="instanceSubmit"
                    disabled={
                      (formType === TYPE_FORMS.DUBLICATE_KIT_SAMPLE && !!sample && !dirty) ||
                      (formType !== TYPE_FORMS.DUBLICATE_KIT_SAMPLE && !dirty) ||
                      !areAllRequiredFieldsFilled ||
                      values?.sampleId
                    }
                    width={"8em"}
                  />
                </ToolTip>
                <FunctionButton
                  name="Cancel"
                  onClick={() => {
                    setValues(initialValues);
                  }}
                  style={{marginLeft: "2em", position: "initial", width: "11em"}}
                  key="instanceCancel"
                  disabled={!dirty}
                  width={"8em"}
                />
              </div>
            )}
          </div>
        </div>
        <QR
          images={sample?.images}
          instance={{...sample, qrCodeUrl: sample?.qrCodeUrl || values?.sampleQRcode}}
          addMethod={addImages}
          deleteMethod={delImg}
          readOnly={!(sample?.sampleId || values?.sampleId) || !onSubmit}
        />
      </div>
      <div className="border border_gap"></div>
      <div className="fGrow" style={{overflowY: "hidden", paddingBottom: "1em"}}>
        <ReactTable
          defaultData={data || []}
          columns={uniTableColumns}
          onUpdate
          selectable={false}
          className={`mb scrollX`}
        />
      </div>
    </div>
  );
};

export default SampleForm;

export const KitSampleCreateForm = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  function onAddNewSample(values) {
    const {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    } = values;
    const data = {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
      kitId: props.kitId,
      //   typeForm,
    };

    dispatch(addNewSample(data)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("ins added"));
        const newS = res.payload;
        dispatch(
          updatePageTabProps({
            pageType: TYPE_FORMS.CREATE_KIT_SAMPLE,
            props: {
              formInitialValues: {
                ...props.initialValues,
                sampleId: newS.sampleId,
                sampleQRcode: newS.qrCodeUrl,
                factoryId: newS?.factoryId,
                skuNumber: newS?.skuNumber || "",
                groupId: newS?.groupId,
                subgroupId: newS?.subgroupId,
                materialId: newS?.materialId,
                categoryId: newS?.categoryId,
                textureId: newS?.textureId,
                collection: newS?.collection || "",
                color: newS?.color || "",
                name: newS?.name || "",
                state: newS?.state || "",
              },
              // kitSamplesList: [res.payload, ...props.kitSamplesList],
            },
          }),
        );
      }
    });
  }
  return <SampleForm {...props} onSubmit={onAddNewSample} />;
};

export const KitSampleUpdateForm = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const updFunc = data => {
    dispatch(updateSample({sampleId: props.sample.sampleId, data})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        let newS = res.payload;
        getInfo(t("ins updated"));
        dispatch(
          updatePageTabProps({
            pageType: props.formType,
            props: {
              // kitSamplesList: props.kitSamplesList.map(samp => {
              //   if (samp.sampleId === res.payload.sampleId) {
              //     return res.payload;
              //   }
              //   return samp;
              // }),
              formInitialValues: {
                ...props.initialValues,
                factoryId: newS?.factoryId,
                skuNumber: newS?.skuNumber || "",
                groupId: newS?.groupId,
                subgroupId: newS?.subgroupId,
                materialId: newS?.materialId,
                categoryId: newS?.categoryId,
                textureId: newS?.textureId,
                collection: newS?.collection || "",
                color: newS?.color || "",
                name: newS?.name || "",
                state: newS?.state || "",
              },
            },
          }),
        );
      }
    });
  };

  const onUpdateSample = values => {
    const {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    } = values;
    const data = {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    };

    Swal.fire({
      title: "",
      text: t("if upd"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        updFunc(data);
      }
    });
  };
  return <SampleForm {...props} onSubmit={onUpdateSample} />;
};

export const KitSampleDublicateForm = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const updFunc = data => {
    dispatch(updateSample({sampleId: props.sample.sampleId, data})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        let newS = res.payload;
        getInfo(t("ins updated"));
        dispatch(
          updatePageTabProps({
            pageType: props.formType,
            props: {
              // kitSamplesList: props.kitSamplesList.map(samp => {
              //   if (samp.sampleId === res.payload.sampleId) {
              //     return res.payload;
              //   }
              //   return samp;
              // }),
              formInitialValues: {
                ...props.initialValues,
                factoryId: newS?.factoryId,
                skuNumber: newS?.skuNumber || "",
                groupId: newS?.groupId,
                subgroupId: newS?.subgroupId,
                materialId: newS?.materialId,
                categoryId: newS?.categoryId,
                textureId: newS?.textureId,
                collection: newS?.collection || "",
                color: newS?.color || "",
                name: newS?.name || "",
                state: newS?.state || "",
              },
            },
          }),
        );
      }
    });
  };

  const onUpdateSample = values => {
    const {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    } = values;
    const data = {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    };
    if (props.formType === TYPE_FORMS.DUBLICATE_KIT_SAMPLE) {
      updFunc(data);
      return;
    }
    Swal.fire({
      title: "",
      text: t("if upd"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        updFunc(data);
      }
    });
  };

  const onDublicateSample = values => {
    const {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    } = values;
    const data = {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    };
    dispatch(dublicateSample(props.baseSample.sampleId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        dispatch(updateSample({sampleId: resp.payload.sampleId, data})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            // let newS = res.payload;
            getSuccess(t("Sample dublicated"));
            // dispatch(
            //   updatePageTabProps({
            //     pageType: props.formType,
            //     props: {
            //       // kitSamplesList: [res.payload, ...props.kitSamplesList],
            //       sample: {
            //         images: resp.payload.images,
            //         qrCodeUrl: resp.payload.qrCodeUrl,
            //         sampleId: res.payload.sampleId,
            //       },
            //       formInitialValues: {
            //         ...props.formInitialValues,
            //         factoryId: newS?.factoryId,
            //         skuNumber: newS?.skuNumber || "",
            //         groupId: newS?.groupId,
            //         subgroupId: newS?.subgroupId,
            //         materialId: newS?.materialId,
            //         categoryId: newS?.categoryId,
            //         textureId: newS?.textureId,
            //         collection: newS?.collection || "",
            //         color: newS?.color || "",
            //         name: newS?.name || "",
            //         state: newS?.state || "",
            //       },
            //     },
            //   }),
            // );
            dispatch(closeCurrentTab());
          }
        });
      }
    });
  };

  return <SampleForm {...props} onSubmit={props?.sample ? onUpdateSample : onDublicateSample} />;
};

export const KitSampleViewForm = props => {
  const [initialValues, setInitial] = useState({
    factoryId: null,
    skuNumber: "",
    groupId: null,
    subgroupId: null,
    materialId: null,
    categoryId: null,
    textureId: null,
    collection: "",
    color: "",
    name: "",
    state: "",
    // kitSku: props?.kit?.skuNumber || "",
    // setDescArea: props?.kit?.name || "",
    // brandManager: props?.kit?.brandManager
    //   ? props?.kit?.brandManager.firstName + " " + props?.kit?.brandManager.lastName
    //   : "",
  });
  const [sample, setSampl] = useState(null);

  useEffect(() => {
    console.log("initialValues :>> ", initialValues);
  }, [initialValues]);

  useEffect(() => {
    fetchData(`/samples/get/${props.sampleId}`, "get").then(res => {
      setSampl(res);
      setInitial(prev => ({...prev, ...res, brandManager: prev.brandManager}));
    });
  }, []);

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form className="h100">
        <SampleForm sample={sample} kitId={props.kitId} setInitialFormValues={setInitial} />
      </Form>
    </Formik>
  );
};
