import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import {addNewSampleImages, deleteSampleImage} from "../../../../slices/SampleFunctionalSlice";
import Divider from "../../../../utilities/Divider/Divider";
import useSwal from "../../../../utilities/hooks/useSwal";
import {TextInput} from "../../../Inputs";
import QRSampleModalPart from "../../../SamplesList/SampleUniModal/SampleUniModalParts/QRSampleModalPart";
import SampleTabPart from "../../SampleTabPart/SampleTabPart";
import useUpdateTab from "../useUpdateTab";

import useDublicateSampleTab from "./useDublicateSampleTab";

const DublicateSampleTabBase = ({setTabs, setTabValue}) => {
  const {t} = useTranslation();

  const {typeForm, sample, qrCodeUrl, setQrCodeUrl} = useDublicateSampleTab();

  useUpdateTab({typeForm, sampleId: sample?.sampleId});

  return (
    <div className="h100">
      <div className="h100" style={{paddingTop: "1em"}}>
        <Form className="fcCol h100">
          <div className="fc jcsb aibl">
            <div className="mb">{t("inst")}</div>
            <TextInput label={t("BM")} width="17em" name="brandManager" disabled />
          </div>
          <Divider />

          <div className="w">
            <SampleTabPart
              typeForm={typeForm}
              setTabs={setTabs}
              setTabValue={setTabValue}
              setQrCodeUrl={setQrCodeUrl}
            />
            <QRSampleModalPart
              qrCodeUrl={qrCodeUrl}
              typeForm={typeForm}
              isCreate={false}
              sample={sample}
              images={sample?.images ?? []}
              addMethod={addNewSampleImages}
              deleteMethod={deleteSampleImage}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

const DublicateSampleTab = ({setTabs, setTabValue}) => {
  // const SWAL = useSwal();
  const {validationSchema, initialValues} = useDublicateSampleTab();

  // useEffect(() => {
  //   SWAL.message({text: "dubl sample open"});
  // }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <DublicateSampleTabBase setTabs={setTabs} setTabValue={setTabValue} />
    </Formik>
  );
};

export default DublicateSampleTab;
