import {useSelector} from "react-redux";
import {t} from "i18next";

import {selectAllUnits} from "../../../slices/SettingsSlice";
import {NumberInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import {deadlinesGridTemplate} from "./Deadlines";

const TermsActionItem = ({id, termName, placeholder}) => {
  const units = useSelector(selectAllUnits);

  const termsOptions =
    units?.map(({unitId, name, multiplicationFactor}) => ({title: t(name), value: unitId, multiplicationFactor})) ?? [];

  return (
    <div className="dg w100 gap" style={{gridTemplate: deadlinesGridTemplate}}>
      <div className="bold">{t(termName)}</div>
      <NumberInput name={`${id}ActionNumber`} precision={0} width="5em" className="bordered" />
      <Select name={`${id}ActionTerm`} options={termsOptions} width="8em" />
      <TextInput
        name={`${id}ActionDescription`}
        placeholder={t(placeholder)}
        generalClassName="fGrow"
        inputClassName="fGrow"
        width="100%"
        disabled
      />
    </div>
  );
};

export default TermsActionItem;
