import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";

import Spinner from "../Components/spinner/Spinner";
import {getCurrentUser, getUser} from "../slices/UserSlice";

const IndexPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const token = useSelector(state => state.user.token);
  // const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUser()).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
      }
    });
  }, [token]);

  if (loading) return <Spinner />;

  if (!user?.role || user?.role?.roleId === 2) {
    return <Navigate to="/accessdenied" replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default IndexPage;
