import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";
import Swal from "sweetalert2";

import useSelectOptions from "../../../hook/useSelectOptions";
import {
  addNewSample,
  clearNewSample,
  dublicateSample,
  selectAllSamples,
  selectNewSample,
  selectSample,
  setSample,
  updateSample,
} from "../../../slices/SampleFunctionalSlice";
import {fetchAllCategories, fetchAllSubGroups} from "../../../slices/SampleSlice/operations";
import {
  clearCurrentTableSample,
  setCurrentSampleId,
  setCurrentTableSample,
  setCurrentTableSampleImages,
  setNoNewSample,
} from "../../../slices/SampleSlice/SampleSlice";
import {selectCurrentSampleId} from "../../../slices/SampleSlice/selectors";
import {getInfo} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../utilities/ToolTip";
import {TextAreaInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {formSampleValues} from "../../KitsList/constants";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";

const SampleTabPart = ({
  setCurrentKitSample = () => {},
  typeForm,
  kitId = null,
  setCurrentSampleId: setCurrentTableSampleId = () => {},
  setTabs = () => {},
  setQrCodeUrl = () => {},
  setTabValue = () => {},
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const TYPES = Object.freeze({
    CREATE: "create",
    EDIT: "edit",
  });
  const type =
    typeForm === TYPE_FORMS.CREATE_SAMPLE || typeForm === TYPE_FORMS.CREATE_KIT_SAMPLES
      ? TYPES.CREATE
      : typeForm === TYPE_FORMS.EDIT_SAMPLE ||
        typeForm === TYPE_FORMS.EDIT_KIT_SAMPLES ||
        typeForm === TYPE_FORMS.DUBLICATE_SAMPLE
      ? TYPES.EDIT
      : null;

  const {values, setValues} = useFormikContext();
  const {materialId, groupId, name, factoryId, skuNumber} = values;
  const {groupsOptions, subGroupsOptions, materialsOptions, texturesOptions, categoriesOptions, factoriesOptions} =
    useSelectOptions();

  const areAllRequiredFieldsFilled =
    type === TYPES.EDIT
      ? skuNumber && groupId && materialId && factoryId && name
      : groupId && materialId && factoryId && name;

  const chosenSample = useSelector(state => state.samples.modalData[typeForm]?.sample);
  const newSample = useSelector(selectNewSample);
  const existingSample = useSelector(selectSample);
  const allSamples = useSelector(selectAllSamples);
  const generalCurrentSampleId = useSelector(selectCurrentSampleId);
  const savedCurrentSampleId = values.currentSampleId;
  const currentSampleId = savedCurrentSampleId || generalCurrentSampleId;
  const currentSample = allSamples?.find(sample => sample.sampleId === currentSampleId) ?? null;

  const sample =
    typeForm === TYPE_FORMS.CREATE_KIT_SAMPLES
      ? chosenSample
      : typeForm === TYPE_FORMS.EDIT_KIT_SAMPLES
      ? currentSample
      : typeForm === TYPE_FORMS.EDIT_SAMPLE || typeForm === TYPE_FORMS.DUBLICATE_SAMPLE
      ? existingSample
      : newSample;
  const isNewSampleAdded = !!useSelector(state => state.samples.modalData[typeForm].isNewSampleAdded);
  const disableFields =
    (typeForm === TYPE_FORMS.CREATE_SAMPLE || typeForm === TYPE_FORMS.CREATE_KIT_SAMPLES) && isNewSampleAdded;

  useEffect(() => {
    if (materialId) {
      dispatch(fetchAllCategories(materialId));
    }
  }, [materialId]);

  useEffect(() => {
    if (groupId) {
      dispatch(fetchAllSubGroups(groupId));
    }
  }, [groupId]);

  const [formChanges, setFormChanges] = useState(false);
  useEffect(() => {
    const isChanged =
      // sample && Object.keys(sample).length === 1 ? false :
      !isEqual(formSampleValues(values), formSampleValues(sample));

    setFormChanges(isChanged);
  }, [values, sample, existingSample]);

  useEffect(() => {
    setTabs(tabs => {
      const beingChangedTabIndex = tabs.findIndex(tab => tab.props.typeForm === typeForm);
      tabs[beingChangedTabIndex].props.wasTabDataChanged = formChanges;
      return tabs;
    });
  }, [formChanges]);

  function clearSample() {
    dispatch(clearNewSample());
    dispatch(clearCurrentTableSample({typeForm}));
    dispatch(setNoNewSample({typeForm}));
    setCurrentTableSampleId(null);
    setCurrentKitSample(null);
    dispatch(setSample(null));
    dispatch(setCurrentSampleId(null));
    dispatch(setCurrentTableSample({typeForm, sample: null}));
    dispatch(setCurrentTableSampleImages({typeForm, sample: null}));
  }

  function onAddMore() {
    setTimeout(() => {
      setValues(prev => ({...prev, ...formSampleValues()}));
    }, []);
    clearSample();
  }

  function onDublicateKitInstance() {
    setTimeout(() => {
      setValues(prev => ({
        ...prev,
        ...formSampleValues(newSample ?? sample),
        skuNumber: "",
        color: "",
        state: "",
      }));
    }, []);
    clearSample();
  }

  function formDataFromValues() {
    const {
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId,
      collection,
      color,
      state,
      subgroupId,
      textureId,
    } = values;
    const data = {
      kitId,
      factoryId,
      groupId,
      materialId,
      name,
      skuNumber,
      categoryId: categoryId || null,
      collection: collection || null,
      color: color || null,
      state: state || null,
      subgroupId: subgroupId || null,
      textureId: textureId || null,
      typeForm,
    };

    return data;
  }

  function onAddNewSample() {
    dispatch(addNewSample(formDataFromValues())).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("ins added"));
        dispatch(setCurrentSampleId(res.payload?.sampleId));
        setValues(prev => ({...prev, skuNumber: res.payload.skuNumber}));
      }
    });
  }

  function onUpdateSample() {
    Swal.fire({
      title: "",
      text: t("if upd"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(updateSample({sampleId: sample.sampleId, data: formDataFromValues()})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getInfo(t("ins updated"));
          }
        });
      }
    });
  }

  function onDublicateSample() {
    dispatch(dublicateSample(sample.sampleId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(updateSample({sampleId: res.payload.sampleId, data: formDataFromValues()})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            setQrCodeUrl(res.payload.qrCodeUrl);
            getInfo(t("ins dublicated"));
            dispatch(setSample(null));
            setTabValue(prev => --prev);
            setTimeout(() => {
              setTabs(tabs => tabs.filter(tab => tab.props.typeForm !== TYPE_FORMS.DUBLICATE_SAMPLE));
            });
          }
        });
      }
    });
  }

  function onCancelCreate() {
    Swal.fire({
      title: "",
      text: t("del data"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        setTimeout(() => {
          setValues(prev => ({brandManager: prev.brandManager, ...formSampleValues()}));
        }, 0);
        dispatch(setNoNewSample({typeForm}));
      }
    });
  }

  function onCancelUpdate() {
    Swal.fire({
      title: "",
      text: t("go back"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        setValues(prev => ({brandManager: prev.brandManager, ...formSampleValues(sample)}));
      }
    });
  }

  return (
    <div className="part_width">
      <div className="lower_selects">
        <TextInput
          label="SKU №"
          name="skuNumber"
          generalClassName="lower_selects_item"
          labelStyles={{width: "4em"}}
          disabled={disableFields}
        />
        <Select
          label={t("Factory") + "*"}
          name="factoryId"
          options={factoriesOptions}
          minFilterLength={1}
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("Smp g") + "*"}
          name="groupId"
          options={groupsOptions}
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("sgr")}
          name="subgroupId"
          options={subGroupsOptions}
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("material") + "*"}
          name="materialId"
          options={materialsOptions}
          minFilterLength={1}
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("categ")}
          name="categoryId"
          options={categoriesOptions}
          minFilterLength={1}
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
        <Select
          label={t("texture")}
          name="textureId"
          options={texturesOptions}
          generalClassName="lower_selects_item"
          readOnly={disableFields}
        />
      </div>
      <div className="fdr jcsb" style={{width: "100.5%"}}>
        <TextAreaInput
          label=""
          name="collection"
          placeholder={t("col") + ": "}
          width="50%"
          height="3em"
          className=""
          disabled={disableFields}
        />
        <TextAreaInput
          label=""
          name="color"
          placeholder={t("Color") + ": "}
          width="50%"
          height="3em"
          className=""
          disabled={disableFields}
        />
      </div>
      <TextAreaInput
        label=""
        name="name"
        placeholder={t("samp nom") + "*" + ": " + t("1000")}
        width="101%"
        height="3em"
        className=""
        disabled={disableFields}
      />
      <TextAreaInput
        label=""
        name="state"
        placeholder={t("samp note") + ": " + t("1000")}
        width="101%"
        height="3em"
        className=""
        disabled={disableFields}
      />

      <div className="fdr jcsb mt">
        {type === TYPES.CREATE && (
          <>
            <FunctionButton
              name="add more"
              onClick={onAddMore}
              disabled={!isNewSampleAdded && !chosenSample?.sampleId}
              width={"8em"}
            />
            <FunctionButton
              name="dubl"
              onClick={onDublicateKitInstance}
              disabled={!isNewSampleAdded && !chosenSample?.sampleId}
              width={"10em"}
            />
          </>
        )}
        <ToolTip
          title={
            type === TYPES.CREATE
              ? "Required fields InstanceModalPart in create"
              : "Required fields InstanceModalPart in edit"
          }
          placement="bottom"
        >
          <FunctionButton
            name={type === TYPES.CREATE || typeForm === TYPE_FORMS.DUBLICATE_SAMPLE ? "Save" : "renew"}
            onClick={
              type === TYPES.CREATE
                ? onAddNewSample
                : typeForm === TYPE_FORMS.DUBLICATE_SAMPLE
                ? onDublicateSample
                : onUpdateSample
            }
            key="instanceSubmit"
            disabled={
              !areAllRequiredFieldsFilled || disableFields || (typeForm !== TYPE_FORMS.DUBLICATE_SAMPLE && !formChanges)
            }
            width={"8em"}
          />
        </ToolTip>
        <FunctionButton
          name="Cancel"
          onClick={type === TYPES.CREATE ? onCancelCreate : onCancelUpdate}
          style={{marginLeft: "2em", position: "initial", width: "11em"}}
          key="instanceCancel"
          disabled={disableFields || Object.values(formSampleValues(values)).every(item => item === "") || !formChanges}
          width={"8em"}
        />
      </div>
    </div>
  );
};

export default SampleTabPart;
