import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../hook/useColumns";
import {
  clearTransferedSamples,
  getTransferedKits,
  getTransferedSamples,
  selectTransferedKits,
  selectTransferedSamples,
} from "../../../slices/SampleFunctionalSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import kitTableDataService from "../../SamplesList/constants/kitTableDataService";
import samplesTableDataService from "../../SamplesList/constants/samplesTableDataService";

import {KitsTransferListLowerTableColumns, KitsTransferListUpperTableColumns} from "./constants";

const useKitsTransferList = () => {
  const dispatch = useDispatch();
  const transferdKits = useSelector(selectTransferedKits),
    transferedSamples = useSelector(selectTransferedSamples);

  const upperTableColumns = useColumns(KitsTransferListUpperTableColumns);
  const upperTableData = kitTableDataService(transferdKits);

  const lowerTableColumns = useColumns(KitsTransferListLowerTableColumns);
  const lowerTableData = samplesTableDataService(transferedSamples);

  const [currentKit, setCurrentKit] = useState(null);
  const [currentSample, setCurrentSample] = useState(null);
  const [currentSampleTerm, setCurrentSampleTerm] = useState("");

  const SampleFilterManager = makeFilterManager("KitsTransferList");

  useEffect(() => {
    if (currentKit) dispatch(getTransferedSamples({kitId: currentKit}));
    else {
      dispatch(clearTransferedSamples());
      setCurrentSample(null);
    }
  }, [currentKit]);

  useEffect(() => {
    let savedFilters_ = SampleFilterManager.getFilters();
    dispatch(getTransferedKits(savedFilters_));
  }, []);

  useEffect(() => {
    const sample = currentSample ? transferedSamples?.find(samp => samp.sampleId === currentSample) : null;
    setCurrentSampleTerm(sample ? dateFormat(sample?.pendingAction?.actionPlanDate) : "");
  }, [currentSample, transferedSamples]);

  const onFilter = params => {
    dispatch(getTransferedKits(params));
    setCurrentKit(null);
  };

  return {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onFilter,
    currentKit,
    setCurrentKit,
    currentSample,
    setCurrentSample,
    currentSampleTerm,
  };
};

export default useKitsTransferList;
