import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchData, fetchList} from "../hook/axios.hook";

export const SETTINGS_STATE_NAME = "settings";

const initialState = {
  timeLimits: [],
  units: [],
  settingsLoading: false,
  error: [],
};

//*** Time limits ***/
export const fetchAllTimeLimits = createAsyncThunk("settings/fetchAllTimeLimits", async () =>
  fetchList("/timeLimits/getAll", "get"),
);
export const updateTimeLimits = createAsyncThunk("settings/updateTimeLimits", async body =>
  fetchData("/timeLimits/update", "patch", body),
);

export const fetchAllUnits = createAsyncThunk("settings/fetchAllUnits", async () =>
  fetchList("/timeLimits/units/getAll", "get"),
);

const SettingsSlice = createSlice({
  name: SETTINGS_STATE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //*** Time limits ***/
      //get
      .addCase(fetchAllTimeLimits.fulfilled, (state, {payload}) => {
        state.timeLimits = payload;
      })
      .addCase(fetchAllUnits.fulfilled, (state, {payload}) => {
        state.units = payload;
      })
      //patch
      .addCase(updateTimeLimits.fulfilled, (state, {payload}) => {
        state.timeLimits = payload.newLimits;
      })

      //matchers
      .addMatcher(
        action => action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      .addMatcher(
        action => action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      .addMatcher(
        action => action.type.endsWith("/rejected"),
        state => handleRejected(state),
      );
  },
});

//matcher functions
function handleFulfilled(state) {
  state.settingsLoading = false;
  state.error = [];
}
function handlePending(state) {
  state.settingsLoading = true;
  state.error = [];
}
function handleRejected(state, error) {
  state.settingsLoading = false;
  state.error = error;
}

//actions, reducer
const {actions, reducer} = SettingsSlice;
export const {_} = actions;
export default reducer;

export const selectAllTimeLimits = state => state[SETTINGS_STATE_NAME].timeLimits;
export const selectAllUnits = state => state[SETTINGS_STATE_NAME].units;
