import {useEffect, useMemo, useState} from "react";
import accounting from "accounting";
import {useField} from "formik";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

const NumberInput = ({
  label,
  width = "67%",
  height,
  className = "",
  question = null,
  autolabel,
  readOnly,
  labelStyles,
  labelWrap,
  placeholder,
  inputClassName,
  handleInputChange = () => {},
  noFormat = false,
  disabled = false,
  arrows = false,
  precision = 2,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState("");

  const {setValue: setRawValue} = helpers;

  useEffect(() => {
    if (field.value === "") {
      setValue("");
    } else noFormat ? setValue(field.value) : setValue(accounting.formatNumber(field.value, options));
  }, [field.value]);

  const options = useMemo(() => {
    return {
      // symbol : "€",
      decimal: ".",
      thousand: "'",
      precision,
      format: "%s%v",
    };
  }, []);

  const handleChange = e => {
    const rowValue = e.target.value;
    const value = rowValue === "" ? 0 : Number.parseFloat(e.target.value);

    if (isNaN(value) || value < 0 || value === "-") {
      setValue(prev => prev);
      return;
    }
    handleInputChange(e);
    setValue(value);
  };

  return (
    <div className={`project_input ${labelWrap ? "" : "labelWrap"}`}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative", height: height}}>
        <div className={className}>
          <input
            readOnly={readOnly}
            autoComplete="off"
            value={value ?? ""}
            onBlur={() => {
              setRawValue(value);
            }}
            onChange={handleChange}
            // type="number"
            className={`${inputClassName} ${!arrows ? "inputNoArrows" : ""}`}
            placeholder={placeholder}
            style={{height: height}}
            disabled={disabled}
          />
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} /> : null}
    </div>
  );
};

NumberInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  props: PropTypes.array,
  className: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  autolabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  noFormat: PropTypes.bool,
};

export default NumberInput;
