import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import {trim} from "lodash";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {fetchList} from "../../../hook/axios.hook";
import useColumns from "../../../hook/useColumns";
import useContextMenu, {ContextMenu, ContextMenuOption} from "../../../hook/useContextMenu";
import useSelectOptions from "../../../hook/useSelectOptions";
import AddIconPng from "../../../images/icons/8201370_add_plus_button_ui_new_icon.png";
import EditIconPng from "../../../images/icons/8725775_edit_icon.png";
import TrashIconPng from "../../../images/icons/8726424_trash_alt_icon.png";
import {
  addNewUser,
  deleteUser,
  getAllUsers,
  getCurrentUser,
  getSuccess,
  getUsers,
  updateUser,
} from "../../../slices/UserSlice";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import ToolTip from "../../../utilities/ToolTip";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {ManagerRegistrColumns} from "../constants/columns";
import mngRegistrTableDataService from "../constants/mngRegistrTableDataService";

import AddIcon from "./svg/AddIcon";
import PencilIcon from "./svg/PencilIcon";
import TrashIcon from "./svg/TrashIcon";

import "./mngRegistr.scss";

const SampleRegistrManager = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const columns = useColumns(ManagerRegistrColumns);

  const {officesOptions, rolesOptions} = useSelectOptions();
  const {menuVisible, handleContextMenu, menuPosition} = useContextMenu();
  const [userStatusOptions, setUserStatusOptions] = useState([]);
  const allUsers = useSelector(getUsers),
    currUser = useSelector(getCurrentUser);

  useEffect(() => {
    fetchList(`/users/status/types/getAll`).then(res => {
      setUserStatusOptions(res.map(status => ({value: status.typeId, title: status.name})));
    });
    if (allUsers?.length === 0) {
      dispatch(getAllUsers());
    }
  }, []);

  const [currentMngUser, setCurrentMngUser] = useState(null);
  const [currentUserFromMenu, setCurrentUserFromMenu] = useState(null);
  const [mode, setMode] = useState(null); /// "EDIT" or "CREATE"
  const initial = {
    firstName: "",
    lastName: "",
    email: "",
    roleId: "",
    officeId: null,
    status: null,
  };
  const [initialValues, setInitialValues] = useState(initial);

  const mngTableData = mngRegistrTableDataService(allUsers);

  const handleSubmit = (values, {resetForm}) => {
    if (mode === "CREATE")
      dispatch(
        addNewUser({
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          },
          roleId: values.roleId,
          officeId: values.officeId,
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          resetForm();
          getSuccess("User created");
          setMode(null);
          setCurrentMngUser(res.payload);
        }
      });
    else if (mode === "EDIT") {
      dispatch(
        updateUser({
          userId: currentMngUser.userId,
          user: {
            status: {
              typeId: values.status,
              validTo: values?.statusTerm || null,
            },
          },
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          resetForm();
          getSuccess("User status updated");
          setMode(null);
          setCurrentMngUser(res.payload.user);
        }
      });
      console.log("edit");
    }
  };

  const onDeleteUser = () => {
    const isSuperuser = currentMngUser.role.roleId === 3;

    Swal.fire({
      title: "",
      text: t("del inf"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        if (currentMngUser.userId === currUser.userId) {
          Swal.fire({
            title: "",
            text: t("curr mng del"),
            icon: "info",
            confirmButtonText: t("Ok"),
            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          return;
        } else if (isSuperuser) {
          Swal.fire({
            title: "",
            text: t("super user del"),
            icon: "info",
            confirmButtonText: t("Ok"),
            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
        } else {
          dispatch(deleteUser(currentMngUser.userId));
          setCurrentMngUser(null);
        }
      }
    });
  };

  const onSelectUser = id => {
    if (currentMngUser && id === currentMngUser.userId) {
      setCurrentMngUser(null);
      return;
    }
    const currUser = allUsers.find(user => user.userId === id);
    setCurrentMngUser(currUser);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      // .required(t("ent name"))
      .matches(/^[a-zA-Zа-яА-ЯІіЇїЄєҐґ'-]+$/, t("n inf")),
    lastName: Yup.string()
      .required(t("ent ln"))
      .matches(/^[a-zA-Zа-яА-ЯІіЇїЄєҐґ'-]+$/, t("n inf")),
    email: Yup.string().required(t("ent em")).email(t("em valid")),
    roleId: Yup.number().required(t("ch r")),
    officeId: Yup.number().required(t("ch s")),
    status: Yup.number()
      .nullable()
      .test("", t("ch o"), function (item) {
        if (mode === "EDIT") return !!item && item !== "";
        else return true;
      }),
    statusTerm: Yup.string().test("", t("set date"), function (item) {
      if (mode === "EDIT" && (this.parent.status === 3 || this.parent.status === 3)) return !!item && item !== "";
      else return true;
    }),
  });

  useEffect(() => {
    if (mode === "EDIT") {
      if (!currentMngUser) {
        setMode(null);
        return;
      }
      setInitialValues(prev => ({
        ...prev,
        firstName: currentMngUser.firstName,
        lastName: currentMngUser.lastName,
        email: currentMngUser.email,
        roleId: currentMngUser.role.roleId === 3 ? currentMngUser.role.roleName : currentMngUser.role.roleId,
        officeId: currentMngUser.office.officeId,
        status: currentMngUser.status.typeId,
        statusTerm: currentMngUser.status.validTo,
      }));
    } else {
      setInitialValues(initial);
    }
  }, [mode, currentMngUser]);

  const isFieldsDisabled = mode !== "CREATE";

  return (
    <PageContainer title="Users" className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        // validateOnBlur={true}
        validateOnMount={true}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({values, isValid, dirty, resetForm, errors}) => (
          <Form style={{paddingTop: "0.5em"}} className="flex_form h100" id={"context-menu-container"}>
            <ReactTable
              defaultData={mngTableData}
              columns={columns}
              onSelect={onSelectUser}
              current={currentMngUser?.userId || null}
              className={"scrollX"}
              style={{height: "100%", flexGrow: "1"}}
              handleContextMenu={(e, id) => {
                handleContextMenu(e);
                setCurrentUserFromMenu(id);
              }}
              selectOnRightBtn={false}
            />
            <div className="main_registr_fields mt autolabel">
              <div className="fc nowrap gap" style={{padding: "10px", width: "15em"}}>
                <ToolTip title={t("Add user")}>
                  <AddIcon
                    style={{
                      backgroundColor: mode === "CREATE" ? "#567db8" : "transparent",
                    }}
                    color={mode === "CREATE" ? "white" : "#567db8"}
                    onClick={() => {
                      resetForm();
                      setCurrentMngUser(null);
                      setMode(mode === "CREATE" ? null : "CREATE");
                    }}
                  />
                </ToolTip>
                <ToolTip title={t("Edit user")}>
                  <PencilIcon
                    style={{}}
                    color1={mode === "EDIT" ? "white" : "#567db8"}
                    color2={mode === "EDIT" ? "#567db8" : "none"}
                    onClick={() => {
                      setMode(mode === "EDIT" ? null : "EDIT");
                      console.log("red");
                    }}
                    disabled={!currentMngUser}
                  />
                </ToolTip>
                <ToolTip title={t("Delete user")}>
                  <TrashIcon onClick={onDeleteUser} disabled={!currentMngUser} />
                </ToolTip>
              </div>
              <div className="fc nowrap w100 jcc gap2x aist" style={{height: "10em"}}>
                {!!mode && (
                  <>
                    <div className="flex_form ">
                      <TextInput
                        label={t("Last Name") + "*"}
                        readOnly={isFieldsDisabled}
                        name="lastName"
                        style={{width: "17em"}}
                        autolabel
                        width="100%"
                      />
                      <TextInput
                        label={t("Name")}
                        name="firstName"
                        readOnly={isFieldsDisabled}
                        style={{width: "17em"}}
                        autolabel
                        width="100%"
                      />
                      <TextInput
                        label="e-mail*"
                        name="email"
                        readOnly={isFieldsDisabled}
                        style={{width: "17em"}}
                        autolabel
                        width="100%"
                      />
                      <Select
                        label={t("Offic") + "*"}
                        name="officeId"
                        selectOnly={true}
                        options={officesOptions}
                        style={{width: "17em"}}
                        readOnly={isFieldsDisabled}
                        optionsAbove={true}
                      />
                      {currentMngUser?.role?.roleId === 3 ? (
                        <TextInput
                          label={t("role") + "*"}
                          name="roleId"
                          style={{width: "17em"}}
                          readOnly={true}
                          width="100%"
                        />
                      ) : (
                        <Select
                          label={t("role") + "*"}
                          name="roleId"
                          selectOnly={true}
                          options={rolesOptions}
                          style={{width: "17em"}}
                          readOnly={isFieldsDisabled}
                          optionsAbove={true}
                        />
                      )}
                    </div>
                    {mode === "EDIT" && (
                      <div className="fc nowrap" style={{width: "29em", gap: "20px"}}>
                        <Select
                          label={t("Status*")}
                          name="status"
                          selectOnly={true}
                          options={userStatusOptions}
                          style={{width: "17em"}}
                          width="100%"
                          required={true}
                          // readOnly={isFieldsDisabled && mode !== "EDIT"}
                        />
                        {(values.status === 3 || values.status === 4) && <DateInput name="statusTerm" />}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="buttons_mng_reg">
              <div className="btns_t">
                <FunctionButton
                  name="Save"
                  type={BUTTON_TYPES.SUBMIT}
                  disabled={!mode || !isValid || (mode === "EDIT" && !dirty)}
                />
                <FunctionButton
                  name="Cancel"
                  // onClick={() => {
                  //   setModalActive(false);
                  // }}
                  disabled={true}
                />
              </div>
            </div>
            <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
              <ContextMenuOption
                icon={AddIconPng}
                onClick={() => {
                  resetForm();
                  setCurrentMngUser(null);
                  setMode("CREATE");
                }}
                text="Add user"
              />
              <ContextMenuOption
                icon={EditIconPng}
                text={"Edit user"}
                onClick={() => {
                  setMode("EDIT");
                  if (currentUserFromMenu !== currentMngUser.userId) {
                    onSelectUser(currentUserFromMenu);
                  }
                }}
                disabled={!currentMngUser}
              />

              <Divider />
              <ContextMenuOption
                icon={TrashIconPng}
                text="Delete user"
                onClick={onDeleteUser}
                disabled={!currentMngUser}
              />
            </ContextMenu>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

export default SampleRegistrManager;
